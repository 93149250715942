import { render, staticRenderFns } from "./FiscalResult.vue?vue&type=template&id=1ce9cc4c&scoped=true&"
import script from "./FiscalResult.vue?vue&type=script&lang=js&"
export * from "./FiscalResult.vue?vue&type=script&lang=js&"
import style0 from "./FiscalResult.vue?vue&type=style&index=0&id=1ce9cc4c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ce9cc4c",
  null
  
)

export default component.exports